/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable react/prop-types */
import * as React from 'react';

import Navbar from './Navbar';
import Footer from './Footer';

import './layout.scss';

const Layout = ({ children, global, pageContext }) => {
  const { navbar, footer } = global;
  return (
    <>
      <Navbar navbar={navbar} pageContext={pageContext} />
      {children}
      <Footer footer={footer} navbar={navbar} pageContext={pageContext} />
    </>
  );
};

export default Layout;
