/* eslint-disable operator-linebreak */
/* eslint-disable import/named */
/* eslint-disable import/no-webpack-loader-syntax */
/* eslint-disable no-shadow */
import React, { useEffect, useState, useRef } from 'react';

import PropTypes from 'prop-types';
import { navigate } from 'gatsby-link';
import { Link } from 'gatsby';
import { MdExpandMore } from 'react-icons/md';
import { useCookies } from 'react-cookie';

import BA from '!file-loader!../../images/BA.png';
import EN from '!file-loader!../../images/EN.png';

import getLocalizedPage from '../../utils/localize-fetch';
import { localizePath } from '../../utils/localize';
import { useOnClickOutside } from '../../utils/hooks';

import '../Navbar/navbar.css';

const LanguageSelector = ({ pageContext }) => {
  const isMounted = useRef(false);
  const select = useRef();
  const [cookies, setCookie] = useCookies();
  const [locale, setLocale] = useState(
    cookies.GATSBY_LOCALE || pageContext.locale,
  );
  const [showing, setShowing] = useState(false);

  const currentFlag = (locale) => {
    switch (locale) {
      case 'bs': {
        return BA;
      }
      case 'en': {
        return EN;
      }
      default:
        return BA;
    }
  };

  const handleLocaleChange = async (selectedLocale) => {
    setCookie('GATSBY_LOCALE', selectedLocale, {
      path: '/',
      secure: process.env.NODE_ENV,
      sameSite: 'Strict',
    });
    setLocale(selectedLocale);
  };
  const handleLocaleChangeRef = useRef(handleLocaleChange);
  useOnClickOutside(select, () => setShowing(false));

  useEffect(() => {
    if (!cookies.GATSBY_LOCALE) {
      handleLocaleChangeRef.current(pageContext.defaultLocale);
    }

    const changeLocale = async () => {
      setShowing(false);
      if (
        !isMounted.current &&
        cookies.GATSBY_LOCALE &&
        cookies.GATSBY_LOCALE !== pageContext.locale
      ) {
        const localePage = await getLocalizedPage(
          cookies.GATSBY_LOCALE,
          pageContext,
        );
        navigate(localizePath({ ...pageContext, ...localePage }));
      }
    };

    changeLocale();

    return () => {
      isMounted.current = true;
    };
  }, [locale, pageContext, cookies.GATSBY_LOCALE]);

  return (
    <div ref={select} className="relative w-full mb-16 md:mb-0">
      <button
        type="button"
        className="language-selector hover:bg-primary-50 hover:text-primary-600 focus:bg-primary-50 focus:text-primary-600 focus:outline-none flex items-center justify-start cursor-pointer rounded-md"
        onClick={() => setShowing(!showing)}
      >
        <span className="uppercase me-1 fs-md-5-secondary font-bold">
          {locale}
        </span>
        <MdExpandMore className="me-1 text-primary-600" />
      </button>
      <button
        type="button"
        className="language-selector-mobile w-100 hover:bg-primary-50 hover:text-primary-600 focus:bg-primary-50 focus:text-primary-600 focus:outline-none flex items-center justify-start cursor-pointer rounded-md text-white"
        onClick={() => setShowing(!showing)}
      >
        <span className="uppercase text-lg mr-2 lg:text-base font-bold">
          {locale}
        </span>
        <MdExpandMore className="text-primary-600 ms-auto fs-5" />
      </button>
      <div
        className={`w-20 bg-white p-1 mt-1 shadow-lg rounded-0 ${
          showing ? 'absolute' : 'hidden'
        }`}
      >
        {pageContext.localizedPaths?.map(({ href, locale }) => (
          <Link
            to={href}
            key={locale}
            onClick={() => handleLocaleChange(locale)}
            role="option"
            className="flex justify-start ps-2 items-center w-20"
          >
            <img className="w-6 h-4" src={currentFlag(locale)} alt={locale} />
            <p className="language-text-color uppercase hover:bg-primary-50 hover:text-primary-600 h-full w-full p-2 cursor-pointer rounded-md">
              {locale}
            </p>
          </Link>
        ))}
      </div>
    </div>
  );
};

LanguageSelector.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    defaultLocale: PropTypes.string.isRequired,
    localizedPaths: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        href: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
};

export default LanguageSelector;
