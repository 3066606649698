/* eslint-disable react/require-default-props */

import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { linkPropTypes } from '../../utils/types';

import '../Navbar/navbar.css';

const CustomLink = ({ link, children, onClick }) => {
  const isInternalLink = link.url.startsWith('/');
  if (isInternalLink) {
    return (
      <Link onClick={onClick} activeClassName="font-bold" to={link.url}>
        {children}
      </Link>
    );
  }

  return (
    <a
      className="language-text"
      href={link.url}
      target={link.newTab ? '_blank' : '_self'}
      rel="noreferrer"
    >
      {children}
    </a>
  );
};

CustomLink.propTypes = {
  onClick: PropTypes.func,
  link: linkPropTypes,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default CustomLink;
