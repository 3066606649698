/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable import/no-webpack-loader-syntax */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { FaChevronDown } from 'react-icons/fa';

import ETF1 from '!file-loader!../../images/1200px-Univerzitet_u_Sarajevo_logo.svg.png';
import ETF2 from '!file-loader!../../images/path2221.svg';
import { localizePath } from '../../utils/localize';
import LanguageSelector from '../LanguageSelector';
import CustomLink from '../CustomLink';

import './navbar.scss';

const Navbar = ({ navbar, pageContext }) => (
  <div>
    <nav className="navbar navbar-expand-lg main-navbar">
      <div className="container-fluid main-container justify-content-between p-lg-0 col-md-12">
        <div className="brand-container d-flex">
          <CustomLink
            className="navbar-brand"
            to="/"
            link={{
              url: `${localizePath({
                ...pageContext,
                slug: '',
              })}`,
            }}
          >
            <div className="d-flex">
              <img
                className="d-none d-xl-inline"
                src={ETF1}
                alt=" "
                style={{ width: '80px', height: '80px' }}
              />
              <img
                className="ms-1"
                src={ETF2}
                alt=" "
                style={{ width: '80px', height: '80px' }}
              />
            </div>
          </CustomLink>
        </div>
        <span className="ms-auto mt-1 me-3 search-icon d-lg-none" />
        <button
          className="navbar-toggler border-0"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="true"
          aria-label="Toggle navigation"
        >
          <input className="" id="menu__toggle" type="checkbox" />
          <label className="menu__btn ms-auto" htmlFor="menu__toggle">
            <span />
          </label>
        </button>
        <div
          className="collapse navbar-collapse main-links"
          id="navbarNavDropdown"
        >
          <ul className="navbar-nav main-ul ps-3 ps-lg-0 ms-auto me-4 me-lg-0">
            <li className="nav-item main-li">
              <CustomLink
                className="language-text"
                link={{
                  url: `${localizePath({
                    ...pageContext,
                    isPreview: false,
                    slug: navbar.mainLinks[0].url.slice(1),
                  })}`,
                }}
                to={navbar.mainLinks[0].url}
              >
                <a className="nav-link main-link">
                  {navbar.mainLinks[0].title}
                </a>
              </CustomLink>
            </li>
            <li className="nav-item main-li">
              <CustomLink
                className="language-text"
                link={{
                  url: `${localizePath({
                    ...pageContext,
                    isPreview: false,
                    slug: navbar.mainLinks[1].url.slice(1),
                  })}`,
                }}
                to={navbar.mainLinks[1].url}
              >
                <a className="nav-link main-link">
                  {navbar.mainLinks[1].title}
                </a>
              </CustomLink>
            </li>
            <li className="nav-item main-li d-flex dropdown select-dropdown">
              <Link
                className="nav-link main-link py-0"
                to={navbar.mainLinks.url}
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {navbar.mainLinks[2].title}

                <span className="ms-2 ms-lg-1 me-lg-0">
                  <FaChevronDown className="arrow-down align-items-center my-auto" />
                </span>
              </Link>
              <ul
                className="dropdown-menu border-0 rounded-0 departments-title"
                aria-labelledby="navbarDropdown"
              >
                {navbar.ETFDepartment.map((item) => (
                  <li key={item.title}>
                    <CustomLink
                      className="language-text"
                      link={{
                        ...item,
                        url: `${localizePath({
                          ...pageContext,
                          isPreview: false,
                          slug: item.url.slice(1),
                        })}`,
                      }}
                      to={item.url}
                    >
                      <a className="dropdown-item second-do main-link">
                        {item.title}
                      </a>
                    </CustomLink>
                  </li>
                ))}
              </ul>
            </li>
            <li className="nav-item main-li dropdown">
              <CustomLink
                className="language-text"
                link={{
                  url: `${localizePath({
                    ...pageContext,
                    isPreview: false,
                    slug: navbar.mainLinks[3].url.slice(1),
                  })}`,
                }}
                to={navbar.mainLinks[3].url}
              >
                <a className="nav-link main-link">
                  {navbar.mainLinks[3].title}
                </a>
              </CustomLink>
            </li>
            <li className="nav-item main-li d-flex dropdown select-dropdown">
              <Link
                className="nav-link main-link py-0"
                to={navbar.mainLinks.url}
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {navbar.mainLinks[4].title}

                <span className="ms-2 ms-lg-1 me-lg-0">
                  <FaChevronDown className="arrow-down align-items-center my-auto" />
                </span>
              </Link>
              <ul
                className="dropdown-menu border-0 rounded-0 departments-title"
                aria-labelledby="navbarDropdown"
              >
                {navbar.regulations.map((item) => (
                  <li
                    className="language-text"
                    key={item.title}
                  >
                    <a
                      className="second-do main-link regulation-links"
                      href={item.url}
                    >
                      {item.title}

                    </a>
                  </li>
                ))}
              </ul>
            </li>
            <li className="nav-item main-li">
              <CustomLink
                className="language-text"
                link={{
                  url: `${localizePath({
                    ...pageContext,
                    isPreview: false,
                    slug: navbar.mainLinks[5].url.slice(1),
                  })}`,
                }}
                to={navbar.mainLinks[5].url}
              >
                <a className="nav-link main-link">
                  {navbar.mainLinks[5].title}
                </a>
              </CustomLink>
            </li>
            <li className="nav-item main-li">
              <CustomLink
                className="language-text"
                link={{
                  url: `${localizePath({
                    ...pageContext,
                    isPreview: false,
                    slug: navbar.mainLinks[6].url.slice(1),
                  })}`,
                }}
                to={navbar.mainLinks[6].url}
              >
                <a className="nav-link main-link">
                  {navbar.mainLinks[6].title}
                </a>
              </CustomLink>
            </li>
            <li className="nav-item main-li">
              <CustomLink
                className="language-text"
                link={{
                  url: `${localizePath({
                    ...pageContext,
                    isPreview: false,
                    slug: navbar.mainLinks[7].url.slice(1),
                  })}`,
                }}
                to={navbar.mainLinks[7].url}
              >
                <a className="nav-link main-link">
                  {navbar.mainLinks[7].title}
                </a>
              </CustomLink>
            </li>
            <li className="nav-item main-li">
              <CustomLink
                className="language-text"
                link={{
                  url: `${localizePath({
                    ...pageContext,
                    isPreview: false,
                    slug: navbar.mainLinks[8].url.slice(1),
                  })}`,
                }}
                to={navbar.mainLinks[8].url}
              >
                <a className="nav-link main-link">
                  {navbar.mainLinks[8].title}
                </a>
              </CustomLink>
            </li>
            <div className="language-box text-white hidden lg:block">
              <LanguageSelector pageContext={pageContext} />
            </div>
            <div className="d-lg-none mobile-visible-links">
              <div className="language-box d-flex text-dark border-start-1 mt-2 mb-2">
                <LanguageSelector pageContext={pageContext} />
              </div>
            </div>
          </ul>
        </div>
      </div>
    </nav>
  </div>
);
Navbar.propTypes = {
  navbar: PropTypes.shape({
    mainLinks: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        url: PropTypes.string,
        title: PropTypes.string,
      }),
    ),
    secondLinks: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        title: PropTypes.string,
      }),
    ),
    ETFDepartment: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        title: PropTypes.string,
      }),
    ),
    regulations: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        title: PropTypes.string,
      }),
    ),
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    defaultLocale: PropTypes.string.isRequired,
    localizedPaths: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        href: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
};

export default Navbar;
