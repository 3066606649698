/* eslint-disable import/no-webpack-loader-syntax */
import React from 'react';
import PropTypes from 'prop-types';
import { GoChevronRight } from 'react-icons/go';
import ReactMarkdown from 'react-markdown';
import { Link } from 'gatsby';

import ETF2 from '!file-loader!../../images/path2221.svg';
import ETF1 from '!file-loader!../../images/1200px-Univerzitet_u_Sarajevo_logo.svg.png';
import { localizePath } from '../../utils/localize';
import CustomLink from '../CustomLink';

import './footer.scss';

const Footer = ({ pageContext, footer, navbar }) => (
  <div>
    <div className=" footer mt-5 pb-5">
      <div className="col-lg-9 col-xl-12 mt-5 mx-auto justify-content-center  main-container">
        <div className="row">
          <div className="col d-flex img-social">
            <Link className="image d-none d-lg-flex" to="/">
              <img className="img1" src={ETF1} alt=" " />
              <img className="img2 ms-3" src={ETF2} alt=" " />
            </Link>
            {/* Mobile device */}
            <Link className="image-mobile ms-md-0 d-lg-none" to="/">
              <img className="img1" src={ETF1} alt=" " />
              <img className="img2 ms-3" src={ETF2} alt=" " />
            </Link>
          </div>
        </div>
        <div className="row mb-5 d-lg-flex justify-content-between m-lg-0">
          <div className="col-lg-4 ms-md-0 ms-lg-0 ps-lg-0 col department width-column mb-5 mb-lg-0">
            <h2 className="text-white title">{footer.ETFDepartments.title}</h2>
            <div className="department-container">
              {footer.ETFDepartmentLinks.map((item) => (
                <div key={item.id}>
                  <CustomLink
                    className="language-text"
                    link={{
                      url: `${localizePath({
                        ...item,
                        ...pageContext,
                        isPreview: false,
                        slug: item.url.slice(1),
                      })}`,
                    }}
                    to={`/${item.url}`}
                  >
                    <div className="d-flex">
                      <span>
                        <GoChevronRight className="me-1 right-sign" />
                      </span>
                      <p className="text-white content pb-1 d-flex">
                        {item.title}
                      </p>
                    </div>
                  </CustomLink>
                </div>
              ))}
            </div>
          </div>
          <div className="col-lg-4 col-12 ms-md-0 ms-lg-0 ps-lg-0 address width-column">
            <h2 className="text-white title">{footer.address.title}</h2>
            <ReactMarkdown className="text-white address-content">
              {footer.address.description}
            </ReactMarkdown>
          </div>
          <div className="col-lg-3 ms-md-0 ps-lg-0 d-block contact mt-5 mt-lg-0">
            <h2 className="text-white title ">{footer.contact.title}</h2>
            <p className="text-white content ">
              <a href={`tel: ${footer.contact.Tel}`}>
                {` Tel: ${footer.contact.Tel}`}
              </a>
            </p>
            <p className="text-white content ">
              <a href={`fax: ${footer.contact.Fax}`}>
                {` Fax: ${footer.contact.Fax}`}
              </a>
            </p>
            <p className="text-white content ">
              <a href={`mailto:${footer.contact.Email}`}>
                {` Email: ${footer.contact.Email}`}
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="copyright-container pt-3 pb-4 container-fluid bg-color align-items-center">
      <div className="copyright-container-inner container-sm justify-content-between col-lg-8 col-xl-7 d-lg-flex">
        <p className="text-white my-lg-auto">{footer.copyright.title}</p>
        <ul className="navbar-nav secondary-navbar text-white me-lg-3">
          {navbar.secondLinks.slice(0, 4).map((item) => (
            <li className="" key={item.id}>
              <a className="language-text me-2" href={item.url}>
                {item.title}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  </div>
);
Footer.propTypes = {
  footer: PropTypes.shape({
    ETFDepartments: PropTypes.shape({
      title: PropTypes.string,
    }),
    externLinks: PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      url: PropTypes.string,
    }),
    ETFDepartmentLinks: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        url: PropTypes.string,
      }),
    ),
    Social: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        Link: PropTypes.string,
      }),
    ),
    address: PropTypes.shape({
      description: PropTypes.string,
      title: PropTypes.string,
    }),
    contact: PropTypes.shape({
      title: PropTypes.string,
      Tel: PropTypes.string,
      Fax: PropTypes.string,
      Email: PropTypes.string,
    }),
    copyright: PropTypes.shape({
      title: PropTypes.string,
    }),
  }).isRequired,
  navbar: PropTypes.shape({
    secondLinks: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        title: PropTypes.string,
      }),
    ),
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    defaultLocale: PropTypes.string.isRequired,
    localizedPaths: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        href: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
};

export default Footer;
