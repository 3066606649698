// /* eslint-disable no-shadow */
import { fetchAPI } from './api';

export default async function getLocalizedPage(targetLocale, pageContext) {
  const localization = pageContext.locales.find(
    (localizations) => localizations === targetLocale,
  );
  const localePage = await fetchAPI(`/pages/${localization}`);
  return localePage;
}
